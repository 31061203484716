<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="充值记录" left-arrow @click-left="$router.back()"></van-nav-bar>
    </div>
    <div class="mainbox">
      <div class="list" v-for="(v,i) in fillist" :key="i">
        <div class="flex">
          <div>
            <span style="font-size:14px;color:#000">充值账号：</span>
            {{v.account}}
          </div>
          <div style="color:#f60">￥{{v.amount}}</div>
        </div>
        <div class="time" style="margin-top:10px">{{v.successTime}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { order_api } from '../http/api/pay'
export default {
  name: 'TaxiH5webRechorder',

  data() {
    return {
      img: '',
      fillist: [],
    }
  },

  mounted() {
    // this.img = JSON.parse(sessionStorage.getItem('token')).headImg
    // console.log(this.img)
    this.order()
  },

  methods: {
    async order() {
      let res = await order_api({})
      if (res.code == 200) {
        this.fillist = res.data
      }
      console.log(res)
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .mainbox {
    flex: 1;
    overflow-y: scroll;
    .list {
      height: 70px;
      border-bottom: solid 1px #eee;
      background: #fff;
      box-sizing: border-box;
      padding-top: 20px;
      .flex {
        display: flex;
        justify-content: space-between;
        margin: 0 20px;
        color: #333;
      }
      .time {
        margin-left: 20px;
        color: rgb(78, 78, 78);
      }
    }
  }
}
</style>